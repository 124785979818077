@import "../../styles/variable.module.scss";
.notFound_details {
  .mainSvg {
    svg{
      @include mobile_device {
        width: 100%;
        max-height: 250px;
      }
      // @media screen and (max-height: 767px) {
      //   height: 250px;
      // }
    }
  }
  .head {
    margin-top: 40px;
    @media screen and (max-height: 767px) {
      font-size: 32px;
    }
  }
  .text {
    font-size: 24px;
    line-height: 32px;
    color: $customFont;
    margin: 30px auto 50px;
    @include medium_device {
      font-size: 14px;
      line-height: 24px;
      margin-top: 14px;
    }
    @media screen and (max-height: 767px) {
      font-size: 14px;
      line-height: 24px;
      max-width: 100%;
      margin: 14px 0 30px;
    }
  }
}

[dir="rtl"] {
  .notFound_details{
    .text + [class*="btn"]{
      svg{
        transform: rotate(180deg);
      }
    }
  }
}
